import React from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import MuiAppBar from "@mui/material/AppBar";
import { Toolbar, Typography, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../storage/authSlice";
import { resetHeaderInfo } from "../../storage/headerInfoSlice";
import HeaderInfo from "./HeaderInfo";

export const Header = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const { token, user } = useSelector((state) => state.auth);

  const handleDrawerOpen = () => {
    sessionStorage.setItem("open", true);
    setOpen(true);
  };

  const navigate = useNavigate();

  const handleDrawerClose = () => {
    sessionStorage.setItem("open", false);
    setOpen(false);
  };

  const btnExitHandler = (e) => {
    e.preventDefault();
    dispatch(logout());
    dispatch(resetHeaderInfo());
  };

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  return (
    <AppBar position="fixed" open={open}>
      <Toolbar>
        {token && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
        )}
        {token && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerClose}
            edge="start"
            sx={{ mr: 2, ...(!open && { display: "none" }) }}
          >
            <MenuOpenIcon />
          </IconButton>
        )}
        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
          AversApp v2.2.8
        </Typography>
        <HeaderInfo />
        {token && (
          <Box sx={{ display: "flex", alignItems: "center", gap: 5 }}>
            <IconButton color="inherit" onClick={() => navigate("/")}>
              <HelpOutlineIcon />
            </IconButton>
            <Typography>{user}</Typography>
            <IconButton color="inherit" onClick={btnExitHandler}>
              <LogoutIcon />
            </IconButton>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};
