import {
  Box,
  Button,
  // Checkbox,
  // FormControlLabel,
  // FormGroup,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import MuiAutoComplete from "../MuiAutoComplete";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import FormHeader from "../FormHeader";
import { requiredText } from "../../utils/constants";
import { useLocation } from "react-router-dom";
import { MuiTextField } from "../MuiTextField";
import { handleFormat, parseNumber } from "../../utils/functions";

const flexStyle = {
  display: "flex",
  gap: "10px",
  alignItems: "center",
  justifyContent: "space-between",
  flexWrap: "wrap",
};

export const AddFormSP = ({
  settings,
  submitForm,
  submitFormBase,
  data = {},
  loading,
}) => {
  const location = useLocation();
  const methods = useForm({
    defaultValues: {
      источник: "",
      адрес: "",
      геокоординаты: "",
      площадь_подвал: "0",
      площадь_цоколь: "0",
      площадь_первый: "0",
      площадь_второй: "0",
      площадь_третий: "0",
      адм_округ: null,
      вход_расп: null,
      вход_тип: null,
      дата_предложения: null,
      зона: null,
      метро_время: null,
      метро_имя: null,
      метро_расст: "",
      ндс: null,
      нэи: null,
      объект_тип: null,
      окна_тип: null,
      отделка: null,
      парковка: null,
      санузел: null,
      скан: "",
      торг: null,
      цена_продажи: "",
      тип_здания_аналог: null,
      высота_потолков: null,
    },
  });

  const { control, watch, handleSubmit, setValue, formState, getValues } =
    methods;

  const { errors } = formState;

  useEffect(() => {
    if (Object.keys(data).length) {
      for (let key in data) {
        setValue(key, data[key]);
      }
    }
  }, [setValue, data]);

  const [s1, s2, s3, s4, s5, price] = watch([
    "площадь_подвал",
    "площадь_цоколь",
    "площадь_первый",
    "площадь_второй",
    "площадь_третий",
    "цена_продажи",
  ]);

  const udelPrice = (s1, s2, s3, s4, s5, price) => {
    let sumSquare =
      Number(s1) + Number(s2) + Number(s3) + Number(s4) + Number(s5);
    if (sumSquare > 0) {
      return Math.round(parseNumber(price) / sumSquare);
    } else {
      return "";
    }
  };

  return (
    <FormProvider {...methods}>
      <form>
        <FormHeader disabled={location.pathname.includes("Edit")} />
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Box m={2} sx={flexStyle}>
            <Controller
              control={control}
              name="площадь_подвал"
              render={({ field: { onChange, value } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  onFocus={() => {
                    if (getValues().площадь_подвал === "0") {
                      setValue("площадь_подвал", "");
                    }
                  }}
                  onBlur={() => {
                    if (getValues().площадь_подвал === "") {
                      setValue("площадь_подвал", "0");
                    }
                  }}
                  label="Подвал"
                  type={"number"}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">м²</InputAdornment>
                    ),
                    "data-test": "площадь_подвал",
                  }}
                  size="small"
                />
              )}
            />
            <Controller
              control={control}
              name="площадь_цоколь"
              render={({ field: { onChange, value } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  onFocus={() => {
                    if (getValues().площадь_цоколь === "0") {
                      setValue("площадь_цоколь", "");
                    }
                  }}
                  onBlur={() => {
                    if (getValues().площадь_цоколь === "") {
                      setValue("площадь_цоколь", "0");
                    }
                  }}
                  label="Цоколь"
                  type={"number"}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">м²</InputAdornment>
                    ),
                    "data-test": "площадь_цоколь",
                  }}
                  size="small"
                />
              )}
            />
            <Controller
              control={control}
              name="площадь_первый"
              render={({ field: { onChange, value } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  onFocus={() => {
                    if (getValues().площадь_первый === "0") {
                      setValue("площадь_первый", "");
                    }
                  }}
                  onBlur={() => {
                    if (getValues().площадь_первый === "") {
                      setValue("площадь_первый", "0");
                    }
                  }}
                  label="1 этаж"
                  type={"number"}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">м²</InputAdornment>
                    ),
                    "data-test": "площадь_первый",
                  }}
                  size="small"
                />
              )}
            />
            <Controller
              control={control}
              name="площадь_второй"
              render={({ field: { onChange, value } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  onFocus={() => {
                    if (getValues().площадь_второй === "0") {
                      setValue("площадь_второй", "");
                    }
                  }}
                  onBlur={() => {
                    if (getValues().площадь_второй === "") {
                      setValue("площадь_второй", "0");
                    }
                  }}
                  label="2 этаж"
                  type={"number"}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">м²</InputAdornment>
                    ),
                    "data-test": "площадь_второй",
                  }}
                  size="small"
                />
              )}
            />
            <Controller
              control={control}
              name="площадь_третий"
              render={({ field: { onChange, value } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  onFocus={() => {
                    if (getValues().площадь_третий === "0") {
                      setValue("площадь_третий", "");
                    }
                  }}
                  onBlur={() => {
                    if (getValues().площадь_третий === "") {
                      setValue("площадь_третий", "0");
                    }
                  }}
                  label="3 этаж"
                  type={"number"}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">м²</InputAdornment>
                    ),
                    "data-test": "площадь_третий",
                  }}
                  size="small"
                />
              )}
            />
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns:
                "repeat(auto-fit, minmax(min(100%/2, max(280px, 100%/3)), 1fr))",
              gap: 1,
            }}
          >
            <Controller
              name="дата_предложения"
              defaultValue={dayjs()}
              control={control}
              rules={{
                required: "Необходимо заполнить",
              }}
              render={({
                field: { onChange, value, ref },
                fieldState: { error },
              }) => (
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="ru"
                >
                  <DatePicker
                    inputRef={ref}
                    value={value}
                    onChange={(event) => {
                      onChange(dayjs(event).format("YYYY-MM-DD"));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={"Дата предложения"}
                        error={!!error}
                        helperText={error?.message}
                        size="small"
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
            <MuiAutoComplete
              rules={{ required: requiredText }}
              name={"торг"}
              label={"Торг"}
              settings={settings}
            />
            <MuiAutoComplete
              rules={{ required: requiredText }}
              name={"адм_округ"}
              label={"Административный округ"}
              settings={settings}
            />
            <MuiAutoComplete
              rules={{ required: requiredText }}
              name={"зона"}
              label={"Кольцевая зона"}
              settings={settings}
            />
            <MuiAutoComplete
              rules={{ required: requiredText }}
              name={"метро_имя"}
              label={"Станция метро"}
              settings={settings}
            />
            <MuiTextField
              rules={{
                min: {
                  value: 0,
                  message: "Значение должно быть больше нуля",
                },
              }}
              name={"метро_расст"}
              label={"Расстояние"}
              type={"number"}
              InputProps={{
                endAdornment: <InputAdornment position="end">м</InputAdornment>,
              }}
            />
            <MuiAutoComplete
              rules={{ required: requiredText }}
              name={"метро_время"}
              label={"Время от метро"}
              settings={settings}
            />
            <MuiAutoComplete
              rules={{ required: requiredText }}
              name={"вход_расп"}
              label={"Расположение входа"}
              settings={settings}
            />
            <MuiAutoComplete
              rules={{ required: requiredText }}
              name={"вход_тип"}
              label={"Тип входа"}
              settings={settings}
            />
            <MuiAutoComplete
              rules={{ required: requiredText }}
              name={"окна_тип"}
              label={"Наличие и размер окон"}
              settings={settings}
            />
            <MuiAutoComplete
              rules={{ required: requiredText }}
              name={"парковка"}
              label={"Парковка"}
              settings={settings}
            />
            <MuiAutoComplete
              rules={{ required: requiredText }}
              name={"объект_тип"}
              label={"Тип объекта"}
              settings={settings}
            />
            <MuiAutoComplete
              rules={{ required: requiredText }}
              name={"отделка"}
              label={"Состояние отделки"}
              settings={settings}
            />
            <MuiAutoComplete
              rules={{ required: requiredText }}
              name={"санузел"}
              label={"Наличие санузла"}
              settings={settings}
            />
            <MuiAutoComplete
              rules={{ required: requiredText }}
              name={"нэи"}
              label={"Наиболее эфф. использ."}
              settings={settings}
            />
            {/* {nai === "Свободного назначения" && (
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Controller
                      name="нэи_торговля"
                      control={control}
                      render={({ field }) => <Checkbox {...field} />}
                    />
                  }
                  label="нэи_торговля"
                />
                <FormControlLabel
                  control={
                    <Controller
                      name="нэи_офисы"
                      control={control}
                      render={({ field }) => <Checkbox {...field} />}
                    />
                  }
                  label="нэи_офисы"
                />
                <FormControlLabel
                  control={
                    <Controller
                      name="нэи_промка"
                      control={control}
                      render={({ field }) => <Checkbox {...field} />}
                    />
                  }
                  label="нэи_промка"
                />
              </FormGroup>
            )} */}
            <Controller
              control={control}
              name="цена_продажи"
              rules={{ required: requiredText, min: 1 }}
              render={({ field: { onChange, ref, value } }) => (
                <TextField
                  value={handleFormat(value)}
                  inputRef={ref}
                  onChange={onChange}
                  label="Цена, руб"
                  variant="outlined"
                  autoComplete="off"
                  error={!!errors["цена_продажи"]}
                  helperText={errors["цена_продажи"]?.message}
                  size="small"
                  InputProps={{
                    "data-test": "цена_продажи",
                  }}
                />
              )}
            />
            <TextField
              value={handleFormat(udelPrice(s1, s2, s3, s4, s5, price))}
              disabled
              label="Удельная стоимость за м2"
              size="small"
            />
            {/* <Controller
              control={control}
              name="скан"
              render={({ field: { onChange, value } }) => (
                <TextField
                  value={value || ""}
                  onChange={onChange}
                  label="Ссылка на скан"
                  variant="outlined"
                  size="small"
                  InputProps={{
                    "data-test": "скан",
                  }}
                />
              )}
            /> */}
            <Controller
              control={control}
              name="телефон"
              render={({ field: { onChange, ref, value } }) => (
                <TextField
                  value={value || ""}
                  inputRef={ref}
                  onChange={onChange}
                  label="Телефон"
                  variant="outlined"
                  size="small"
                  InputProps={{
                    "data-test": "телефон",
                  }}
                />
              )}
            />
            <MuiAutoComplete
              rules={{ required: requiredText }}
              name={"ндс"}
              label={"НДС"}
              settings={settings}
            />
            <MuiAutoComplete
              rules={{ required: requiredText }}
              name={"тип_здания_аналог"}
              label={"Тип здания аналог"}
              settings={settings}
            />
            <MuiAutoComplete
              rules={{ required: requiredText }}
              name={"высота_потолков"}
              label={"Высота потолков"}
              settings={settings}
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button
            variant="contained"
            color="success"
            sx={{ mt: 2 }}
            disabled={loading}
            onClick={handleSubmit(submitForm)}
          >
            {location.state?.cell
              ? "Сохранить только в задании"
              : "Сохранить аналог СП"}
          </Button>
          {location.state?.cell && (
            <Button
              variant="contained"
              color="secondary"
              sx={{ mt: 2 }}
              disabled={loading}
              onClick={handleSubmit(submitFormBase)}
            >
              Сохранить и в задании, в общей базе
            </Button>
          )}
        </Box>
      </form>
    </FormProvider>
  );
};
