import { Box, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const HeaderInfo = () => {
  const location = useLocation();
  const headerInfo = useSelector((state) => state.headerInfo);
  return (
    <>
      {location.pathname === "/Calc" && (
        <Box sx={{ flexGrow: 1, display: "flex", gap: 2 }}>
          {headerInfo.task ? (
            <Typography>Задание {headerInfo.task}</Typography>
          ) : (
            <></>
          )}
          {headerInfo.template ? (
            <Typography>Шаблон {headerInfo.template}</Typography>
          ) : (
            <></>
          )}
        </Box>
      )}
      {((location.pathname === "/EditAnalogSP" && location.state?.cell) ||
        (location.pathname === "/EditAnalogDP" && location.state?.cell)) && (
        <Box sx={{ flexGrow: 1, display: "flex", gap: 2 }}>
          {headerInfo.task ? (
            <Typography>Дата оценки {headerInfo.dateOfAssessment}</Typography>
          ) : (
            <></>
          )}
        </Box>
      )}
    </>
  );
};

export default HeaderInfo;
