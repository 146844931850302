export const onFocusHandle = (e, data, setState) => {
  const newData = { ...data };
  if (e.target.value === "0") {
    newData[e.target.id] = "";
    setState(newData);
  }
};

export const onBlurHandle = (e, data, setState) => {
  const newData = { ...data };
  if (e.target.value === "") {
    newData[e.target.id] = 0;
    setState(newData);
  }
};

export const handleTextField = (e, object, setState) => {
  const newObject = { ...object };
  newObject[e.target.id] = e.target.value;
  setState(newObject);
};

export const handleAutocomlete = (e, newValue, object, setState) => {
  const newObject = { ...object };
  if (e.target.id) {
    newObject[e.target.id.split("-")[0]] = newValue;
  } else {
    if (e.target.parentNode.parentNode.parentNode.previousSibling !== null) {
      newObject[
        e.target.parentNode.parentNode.parentNode.previousSibling.id.split(
          "-"
        )[0]
      ] = "";
    } else {
      newObject[
        e.target.parentNode.parentNode.previousSibling.id.split("-")[0]
      ] = "";
    }
  }
  setState(newObject);
};

export const redirect = (e, url) => {
  window.open(url);
};

export const numFormat = new Intl.NumberFormat("ru-RU");

export const udel_price = (ObjectState, name) => {
  const str = ObjectState[name] + "";
  const sum_square =
    ObjectState["площадь_подвал"] +
    ObjectState["площадь_цоколь"] +
    ObjectState["площадь_первый"] +
    ObjectState["площадь_второй"];
  if (ObjectState[name] === undefined) {
    return;
  } else {
    if (Math.round(str.match(/\d+/g)?.join("")) / sum_square !== Infinity) {
      return Math.round(str.match(/\d+/g)?.join("") / sum_square);
    } else {
      return ObjectState[name];
    }
  }
};

export const formatNumber = (str) => {
  const regDigital = /\d{1,}/g;
  const regTel = /\+7/g;
  try {
    if (str.match(regTel)) {
      return str;
    } else {
      if (str.match(regDigital)) {
        if (str.match(/\d{1,},\d{1,}/g)) {
          return str.replace(",", " ").replace(".", ",");
        } else {
          return str;
        }
      } else {
        return str;
      }
    }
  } catch (error) {
    return numFormat.format(str);
  }
};

export const handleFormatNumber = (e, data, setData) => {
  const newData = { ...data };
  let formatValue = e.target.value.match(/\d+/g);
  if (formatValue) {
    newData[e.target.id] = numFormat.format(formatValue.join(""));
  } else newData[e.target.id] = numFormat.format(formatValue);
  setData(newData);
};

export const transpose = (matrix) =>
  matrix[0].map((col, i) => matrix.map((row) => row[i]));

export const handleFormat = (str) => {
  if (str === 0) return "";
  if (typeof str === "string") {
    const regDigital = /\d{1,}/g;
    let temp = str.match(regDigital);
    if (temp === null) {
      return "";
    } else {
      return numFormat.format(Math.round(temp.join("")));
    }
  } else {
    return numFormat.format(Math.round(str));
  }
};

export const handleFormatRentMonth = (str, square) => {
  if (str === 0) return "";
  if (typeof str === "string") {
    const regDigital = /\d{1,}/g;
    let temp = str.match(regDigital);
    if (temp === null) {
      return "";
    } else {
      return numFormat.format(Math.round((temp.join("") * square) / 12));
    }
  } else {
    return numFormat.format(Math.round((str * square) / 12));
  }
};

export const handleFormatRent = (str) => {
  if (str === 0) return "";
  if (typeof str === "string") {
    const regDigital = /\d{1,}/g;
    let temp = str.match(regDigital);
    if (temp === null) {
      return "";
    } else {
      return numFormat.format(temp.join("") / 12);
    }
  } else {
    return numFormat.format(parseFloat(str) / 12);
  }
};

export const checkSquare = (data) => {
  let sumSquare =
    parseInt(data["площадь_подвал"]) +
    parseInt(data["площадь_цоколь"]) +
    parseInt(data["площадь_первый"]) +
    parseInt(data["площадь_второй"]) +
    parseInt(data["площадь_третий"]);
  if (
    sumSquare > 0 &&
    data["площадь_подвал"] >= 0 &&
    data["площадь_цоколь"] >= 0 &&
    data["площадь_первый"] >= 0 &&
    data["площадь_второй"] >= 0 &&
    data["площадь_третий"] >= 0
  ) {
    return true;
  } else {
    return false;
  }
};

export const parseNumber = (str) => {
  if (typeof str === "string") {
    const temp = str.match(/\d+/g);
    if (temp === null) {
      return str;
    } else {
      return temp.join("");
    }
  } else {
    return str;
  }
};

export function debounce(callback, delay) {
  let timeout;
  return function () {
    clearTimeout(timeout);
    timeout = setTimeout(callback, delay);
  };
}
