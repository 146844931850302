import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddFormSP } from "../Components/AddForm/AddFormSP";
import {
  useGetSAnalogMutation,
  useUpdateSAnalogMutation,
  useUpdateSAnalogSavedMutation,
} from "../storage/services/s_analogs";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { checkSquare, parseNumber } from "../utils/functions";
import { manualUpdate } from "../storage/setupSlice";

const EditAnalogSP = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [id, setId] = React.useState(0);
  const settings = useSelector((state) => state.settings);
  const task = useSelector((state) => state.headerInfo.task);

  const [getSAnalog, state] = useGetSAnalogMutation();
  const [updateSAnalog, updateState] = useUpdateSAnalogMutation();
  const [updateSAnalogSaved, updateSavedState] =
    useUpdateSAnalogSavedMutation();

  useEffect(() => {
    if (location.state) {
      getSAnalog({ id: location.state.cell, task }).then((res) => {
        setId(res.data.analog_data.id);
      });
    } else {
      getSAnalog({ id: 0 }).then((res) => {
        setId(res.data.analog_data.id);
      });
    }
  }, [getSAnalog, location.state, task]);

  const { isErrorGet, data } = state;
  const { isErrorUpdate } = updateState;

  const submitForm = (data) => {
    let sumSquare =
      parseInt(data["площадь_подвал"]) +
      parseInt(data["площадь_цоколь"]) +
      parseInt(data["площадь_первый"]) +
      parseInt(data["площадь_второй"]) +
      parseInt(data["площадь_третий"]);
    if (checkSquare(data)) {
      let newData = { ...data };
      newData["площадь_общая"] = parseInt(sumSquare);
      newData["цена_продажи"] = parseNumber(newData["цена_продажи"]);
      if (location.state?.saved_id) {
        updateSAnalogSaved({
          data: newData,
          s_id: id,
          saved_id: location.state.saved_id,
        }).then((res) => {
          if (!res?.error) {
            alert("Аналог успешно обновлен");
            dispatch(manualUpdate());
          }
        });
      } else {
        updateSAnalog({ data: newData, s_id: id }).then((res) => {
          if (!res?.error) {
            alert("Аналог успешно обновлен");
            dispatch(manualUpdate());
          }
        });
      }
    } else {
      alert("Площадь должна быть больше нуля");
    }
  };

  const submitFormBase = (data) => {
    let sumSquare =
      parseInt(data["площадь_подвал"]) +
      parseInt(data["площадь_цоколь"]) +
      parseInt(data["площадь_первый"]) +
      parseInt(data["площадь_второй"]) +
      parseInt(data["площадь_третий"]);
    if (checkSquare(data)) {
      let newData = { ...data };
      newData["площадь_общая"] = parseInt(sumSquare);
      newData["цена_продажи"] = parseNumber(newData["цена_продажи"]);
      updateSAnalog({ data: newData, s_id: id }).then((res) => {
        if (!res?.error) {
          alert("Аналог успешно обновлен в базе");
          dispatch(manualUpdate());
        }
      });
      updateSAnalogSaved({
        data: newData,
        s_id: id,
        saved_id: location.state.saved_id,
      }).then((res) => {
        if (!res?.error) {
          alert("Аналог успешно обновлен в задании");
          dispatch(manualUpdate());
        }
      });
    } else {
      alert("Площадь должна быть больше нуля");
    }
  };

  if (isErrorGet || isErrorUpdate) {
    return <>Ошибка!</>;
  }

  if (Object.keys(settings.settings).length > 0)
    return (
      <>
        <Typography variant="h5" align="center" mb={2}>
          Редактировать аналог
          {location.state?.cell ? " из сохраненных в задании " + task : ""}
        </Typography>
        <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
          <TextField
            value={id}
            onChange={(e) => setId(e.target.value)}
            id="ID"
            label="ID"
            variant="outlined"
            size="small"
            type={"number"}
            disabled={!!location.state?.cell}
          ></TextField>
          <Button
            variant="outlined"
            size="small"
            onClick={(e) => getSAnalog({ id })}
            disabled={!!location.state?.cell}
          >
            Получить
          </Button>
        </Box>
        <AddFormSP
          settings={settings.settings}
          submitForm={submitForm}
          submitFormBase={submitFormBase}
          data={data?.analog_data}
          loading={updateState.isLoading || updateSavedState.isLoading}
        />
      </>
    );
};

export default EditAnalogSP;
